import React, { useState } from "react";
import { graphql } from "gatsby";
import { Container, Row, Col, Tab, Nav, Modal, Button } from "react-bootstrap";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { SliceZone, PrismicRichText } from "@prismicio/react";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import components from "../components/slices";
import { useTheme } from "../context/ThemeContext";
import { Award, Construction } from "../components/common/Icons";

const Project = ({ data }) => {
  if (!data) return null;
  const { project } = data;
  const [key, setKey] = useState(null); // Key for active info tab

  const {
    currentImageIndex,
    showImageModal,
    allGatsbyImageData,
    setCurrentImageIndex,
    handleCloseImageModal,
    handleShowImageModal,
  } = useTheme();

  // Create an array with all the photos (gatsbyImageData)
  // Push first and second place the primary and seconday photos
  allGatsbyImageData[0] = project.data.primary_image.gatsbyImageData;
  allGatsbyImageData[1] = project.data.secondary_image.gatsbyImageData;
  // Filter the "images" slice types and then only the rows with choosen layout
  // and push the images to the allGatsbyImageData array.
  project.data.body
    .filter((slice) => slice.slice_type === "images")
    .forEach((slice) => {
      slice.items.forEach((item) => {
        if (item.layout) {
          if (item.left_image.gatsbyImageData) {
            allGatsbyImageData.push(item.left_image.gatsbyImageData);
          }
          if (item.right_image.gatsbyImageData) {
            allGatsbyImageData.push(item.right_image.gatsbyImageData);
          }
        }
      });
    });

  const prevImage = () => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(allGatsbyImageData.length - 1);
    } else {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };
  const nextImage = () => {
    if (currentImageIndex === allGatsbyImageData.length - 1) {
      setCurrentImageIndex(0);
    } else {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  return (
    <>
      <Seo page={project} />
      <Layout>
        <Container className="project-page-container">
          <h1 className="py-5">{project.data.title}</h1>
          {project.data.in_construction || project.data.awards_list.length ? (
            <div className="project-badges">
              {project.data.awards_list.length
                ? project.data.awards_list.map((award, index) => {
                    if (award.award.text !== "") {
                      return (
                        <div className="award-badge-container">
                          <Award />
                          <PrismicRichText field={award.award.richText} />
                        </div>
                      );
                    }
                  })
                : null}
              {project.data.in_construction && (
                <div className="construction-badge">
                  <Construction />
                  <span>In Construction</span>
                </div>
              )}
            </div>
          ) : null}
          <Row className="mb-5">
            <Col
              md={6}
              className="d-flex"
              onClick={() => {
                handleShowImageModal(true);
                setCurrentImageIndex(
                  allGatsbyImageData
                    .map((imageData) => imageData.images.fallback.src)
                    .indexOf(
                      project.data.primary_image.gatsbyImageData.images.fallback
                        .src
                    )
                );
              }}
            >
              {project.data.primary_image.gatsbyImageData && (
                <GatsbyImage
                  image={project.data.primary_image.gatsbyImageData}
                  alt={project.data.primary_image.alt || "project image"}
                  className="mb-4 mb-md-0"
                />
              )}
            </Col>
            <Col
              md={6}
              className="d-flex"
              onClick={() => {
                handleShowImageModal(true);
                setCurrentImageIndex(
                  allGatsbyImageData
                    .map((imageData) => imageData.images.fallback.src)
                    .indexOf(
                      project.data.secondary_image.gatsbyImageData.images
                        .fallback.src
                    )
                );
              }}
            >
              {project.data.secondary_image.gatsbyImageData && (
                <GatsbyImage
                  image={project.data.secondary_image.gatsbyImageData}
                  alt={project.data.secondary_image.alt || "project image"}
                />
              )}
            </Col>
          </Row>
          <Tab.Container
            activeKey={key}
            // If the clicked tab is the selected, close the tab. Don't open the other one.
            onSelect={(k) => (key === k ? setKey(null) : setKey(k))}
          >
            <Row className="my-5">
              <Col xl={4} className="tab-nav mb-5 ">
                <Nav variant="pills" className="flex-column">
                  {project.data.project_info_english.richText.length ||
                  project.data.project_info_greek.richText.length ? (
                    <Nav.Item className="mb-4">
                      <Nav.Link eventKey="projectInfo">
                        <h3>
                          <span className="link-underline-hover">
                            Project Info
                          </span>
                        </h3>
                      </Nav.Link>
                    </Nav.Item>
                  ) : null}
                  {project.data.credentials_english.richText.length ||
                  project.data.credentials_greek.richText.length ? (
                    <Nav.Item>
                      <Nav.Link eventKey="credentials">
                        <h3>
                          <span className="link-underline-hover">
                            Credentials
                          </span>
                        </h3>
                      </Nav.Link>
                    </Nav.Item>
                  ) : null}
                </Nav>
              </Col>
              <Col xl={8} className="tab-content">
                <Tab.Content>
                  {project.data.project_info_english.richText.length ||
                  project.data.project_info_greek.richText.length ? (
                    <Tab.Pane eventKey="projectInfo">
                      <Row>
                        {project.data.project_info_english.richText.length ? (
                          <Col sm={6} className="mb-4">
                            <PrismicRichText
                              field={project.data.project_info_english.richText}
                            />
                          </Col>
                        ) : null}
                        {project.data.project_info_greek.richText.length ? (
                          <Col sm={6}>
                            <PrismicRichText
                              field={project.data.project_info_greek.richText}
                            />
                          </Col>
                        ) : null}
                      </Row>
                    </Tab.Pane>
                  ) : null}
                  {project.data.credentials_english.richText.length ||
                  project.data.credentials_greek.richText.length ? (
                    <Tab.Pane
                      eventKey="credentials"
                      className="project-credentials"
                    >
                      <Row>
                        {project.data.credentials_english.richText.length ? (
                          <Col sm={6} className="mb-4">
                            <PrismicRichText
                              field={project.data.credentials_english.richText}
                            />
                          </Col>
                        ) : null}
                        {project.data.credentials_greek.richText.length ? (
                          <Col sm={6}>
                            <PrismicRichText
                              field={project.data.credentials_greek.richText}
                            />
                          </Col>
                        ) : null}
                      </Row>
                    </Tab.Pane>
                  ) : null}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <SliceZone
            slices={project.data.body}
            components={components}
            test={{ name: ["value", 2, 3] }}
          />
        </Container>
      </Layout>
      <Modal
        className="image-modal"
        show={showImageModal}
        onHide={handleCloseImageModal}
        fullscreen
      >
        <Modal.Header className="border-0 pb-0 d-flex justify-content-end">
          <Button
            variant="link"
            className="closeImageModal"
            onClick={handleCloseImageModal}
          >
            <StaticImage src="../images/closeX.svg" alt="next image" />
          </Button>
        </Modal.Header>
        <Modal.Body className="p-2 p-md-4 p lg-5 d-flex justify-content-center">
          <GatsbyImage
            image={allGatsbyImageData[currentImageIndex]}
            alt="project image"
          />
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0 d-flex justify-content-center">
          <Button variant="link" className="prevImage" onClick={prevImage}>
            <StaticImage
              src="../images/rightWhiteArrow.svg"
              alt="previews image"
            />
          </Button>
          <Button variant="link" className="nextImage" onClick={nextImage}>
            <StaticImage src="../images/rightWhiteArrow.svg" alt="next image" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const query = graphql`
  query projectQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    project: prismicProject(lang: { eq: $lang }, id: { eq: $id }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        meta_description
        meta_title
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title
        primary_image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        secondary_image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        project_info_english {
          richText
        }
        project_info_greek {
          richText
        }
        credentials_english {
          richText
        }
        credentials_greek {
          richText
        }
        in_construction
        awards_list {
          award {
            richText
            text
          }
        }
        body {
          ... on PrismicProjectDataBodyImages {
            id
            slice_type
            slice_label
            items {
              layout
              left_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              right_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicProjectDataBodyEmbedVideo {
            id
            slice_type
            slice_label
            items {
              video {
                id
                author_name
                author_url
                embed_url
                type
                title
                thumbnail_width
                thumbnail_url
                thumbnail_height
                provider_url
                prismicId
                provider_name
                html
              }
            }
          }
        }
      }
    }
  }
`;

export default Project;
